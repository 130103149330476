import React, { useContext, useEffect, useRef } from "react";
import styles from "./Footer.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/states";
const Footer = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { page, isMobile, weight, fullWeight, dataResults, updatePolicy } = state;
  const refWrap = useRef(null);
  useEffect(()=>{
    if(page === 1 || (page === 0 && !isMobile)){
      refWrap.current.classList.add(styles.hide)
    }else{
      refWrap.current.classList.remove(styles.hide)
    }
  },[isMobile, page]);
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.copy}>© 2024</div>
        <div className={styles.policy} onClick={()=>updatePolicy(true)}>Privacy policy</div>
      </div>
    </>
  );

}

export default Footer;
