
import React, { useEffect, useState } from "react";

import PathContext from "./context/path";
import StatesContext from "./context/states";
import Main from './pages/Main';
import Header from './components/Header';
import Policy from './components/Policy';
import Footer from './components/Footer';

const App = () => {

  const basename = '.';

  const [isMobile, setMobile] = useState(false);
  const [data, setData] = useState([]);
  const [dataResults, setDataResults] = useState([]);
  const [stage, setStage] = useState(0);
  const [page, setPage] = useState(0);
  const [shownPolicy, setShownPolicy] = useState(false);
  const updateStage = (number) => {
    if (stage >= data.length - 1) {
      setPage(prev => prev + 1)
    }
    setStage(number)
  };
  const updatePage = (number) => setPage(number);
  const updatePolicy = (bool) => setShownPolicy(bool);
  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('body').style.opacity = 1;

    fetch(`${basename}/data/data.json?ver=${Math.floor((Math.random() * 0xfffff * 1000000)).toString(16)}`)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      setData(data.questions);
      setDataResults(data.results);
    });

  }, []);


  useEffect(() => {
    const handleMobile = () => {
      if (window.outerWidth < 768 && !isMobile) {
        setMobile(true);
      }
      if (window.outerWidth > 767 && isMobile) {
        setMobile(false);
      }
    }
    handleMobile();
    window.addEventListener('resize', handleMobile);
    return () => {
      window.removeEventListener('resize', handleMobile);
    }
  }, [isMobile]);

  return (
    <StatesContext.Provider value={{ isMobile: isMobile, data: data, updateStage: updateStage, stage: stage, page: page, updatePage: updatePage, dataResults: dataResults, updatePolicy: updatePolicy, shownPolicy: shownPolicy}}>
      <PathContext.Provider value={basename}>
        <div className={'wrap'}>
          <Policy />
          <Header />
          <Main />
          <Footer />
        </div>
      </PathContext.Provider>
    </StatesContext.Provider>
  );

}


export default App;
