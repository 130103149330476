import React, { useContext } from "react";
import styles from "./Btn.module.scss";
import PathContext from "../../context/path";
const Btn = (props) => {
  const path = useContext(PathContext);
  return (
    <>
      <div className={styles.wrap}>
      {props.children}
      
      <div className={styles.bg}></div>
      </div>
    </>
  );

}

export default Btn;
