import React, { useContext, useEffect, useRef } from "react";
import styles from "./Policy.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/states";
import Btn from '../Btn';
const Policy = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { isMobile, shownPolicy, updatePolicy } = state;
  const refWrap = useRef(null);
  useEffect(() => {
    const wrap = refWrap.current;
    const body = document.body;
    if (shownPolicy) {
      wrap.scrollTop = 0;
      wrap.classList.add(styles.active);
      body.style.overflow = 'hidden';
    } else {

      wrap.classList.remove(styles.active);
      body.style.overflow = '';
    }
  }, [shownPolicy]);
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.inner}>
          <h2 className={styles.title}>Privacy policy</h2>
          <ol className={styles.list}>
            <li>
              <b>What data we collect</b><br />
              <span>We only collect your nicknames in Slack to organize interactions within the project.</span>
            </li>
            <li>
              <b>How we use the collected data</b><br />
              <span>Your nicknames are used exclusively for the prize draw. We do not share your data with third parties or use it for any other purpose.</span>
            </li>
            <li>
              <b>Where the data is stored</b><br />
              <span>All collected data is stored in a secure Google Docs document, accessible only to authorized employees of the company involved in the project. Google ensures the protection of data through measures such as encryption and access controls, in accordance with its Privacy Policy. Additionally, we implement internal measures, including secure accounts and restricted access rights, to protect your data.</span>
            </li>
            <li>
              <b>How long we keep data</b><br />
              <span>Your nicknames will only be kept for the duration of the project and will be deleted upon its completion (31 December 2024).</span>
            </li>
          </ol>
          <div className={styles.border}></div>
          <div className={styles.info}>

            <p><b>Legko Ltd.</b><br />
              Legal address: 195067,St. Petersburg, Mechnikova Ave. 3, office 16<br />
              Postal address: 191186, St. Petersburg, Bolshaya Konyushennaya St., 19/8, 6th floor, INFOGRAPHICS office.
            </p>
            <p><b>Tel.:</b> <a href="tel:+78127080060">+7 (812) 708 0060</a><br />
              <b>TIN:</b> 7804512957 <b>/ KPP:</b> 780401001<br />
              <b>OKPO:</b> 27515962 <b>/ OGRN:</b> 1137847293924
            </p>
            <p><b>General Director</b><br />
              Artem Alekseevich Koleganov<br />
              Email: <a href="mailto:ak@infografika.agency">ak@infografika.agency</a></p>
            <p>This document complies with applicable legal requirements for data protection, including ensuring transparency and user rights.</p>
          </div>
          <div className={styles.btn} onClick={() => updatePolicy(false)}>
            <Btn>Back to the project</Btn>
          </div>
          <div className={styles.bg}>
            <picture>
              <source media="(max-width: 768px)" srcSet={`${path}/img/policy-bg-mobile.svg`} />
              <img src={`${path}/img/policy-bg.svg`} alt="" />
            </picture>
          </div>
        </div>
      </div>


    </>
  );
}

export default Policy;
