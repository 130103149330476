import React, { useContext, useEffect, useState } from "react";
import styles from "./Cover.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/states";
import Btn from '../Btn';
const Cover = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { isMobile, updatePage } = state;
  const [isTransition, setTransition] = useState(false);
  const clickHandler = () => {
    if (isTransition) return;
    setTransition(true);
    updatePage(prev => prev + 1);
  }
  useEffect(()=>{
    if(!isMobile) return;
    document.querySelector('html').classList.add('autoheight');
    return () => {
      document.querySelector('html').classList.remove('autoheight');
    }
  },[]);
  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.inner}>
          <div className={styles.img}>
          <h1 className={styles.title}>
            What kind of a traveler were you in 2024?
          </h1>
          <img src={`${path}/img/cover-img.webp`} alt="" />
          </div>
          <div className={styles.text}>
            <p>Here we are at the end of 2024! We hope that this year has been an exciting journey filled with different stories for everyone in the company. Take the quiz to remember what kind of traveler you were in 2024.</p>
            <p>Don’t forget to leave your Slack contact details afterward to participate in the New Year prize draw.</p>
          </div>
          <div className={styles.btn} onClick={clickHandler}>
            <Btn>LET’S GOOO</Btn>
          </div>
        </div>
        <div className={styles.bg}>
          <picture>
            <source media="(max-width: 768px)" srcSet={`${path}/img/cover-mobile.svg`} />
            <img src={`${path}/img/cover.webp`} alt="" />
          </picture>
        </div>
      </div>
    </>
  );

}

export default Cover;
