import React, { useContext, useEffect, useRef } from "react";
import styles from "./Hint.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/states";
const Hint = (props) => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { data, stage, isMobile, updateStage } = state;
  const refWrap = useRef(null);
  const refIcon = useRef(null);
  const clickHanlder = () => {
    if (!isMobile) return;
    const wrap = refWrap.current;
    const checkBoundaries = (e) => {
      const withinBoundaries = e.composedPath().includes(wrap);
      if (withinBoundaries) {
        return;
      }
      wrap.classList.remove(styles.active);
      document.removeEventListener('click', checkBoundaries);
    }
    if (!wrap.classList.contains(styles.active)) {
      wrap.classList.add(styles.active);
      document.addEventListener('click', checkBoundaries);
    } else {
      wrap.classList.remove(styles.active);
      document.removeEventListener('click', checkBoundaries);
    }

  }
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.icon} onClick={clickHanlder} ref={refIcon}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#8377F1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.08984 8.99996C9.32495 8.33163 9.789 7.76807 10.3998 7.40909C11.0106 7.05012 11.7287 6.9189 12.427 7.03867C13.1253 7.15844 13.7587 7.52148 14.2149 8.06349C14.6712 8.60549 14.9209 9.29148 14.9198 9.99996C14.9198 12 11.9198 13 11.9198 13" stroke="#8377F1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 17H12.01" stroke="#8377F1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className={styles.body}>
          <div className={styles.inner}>
            {props.children}
          </div>
        </div>
      </div>
    </>
  );

}

export default Hint;
