import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./Test.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/states";
const Test = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { data, stage, isMobile, updateStage } = state;
  const refInfo = useRef(null);
  const refInner = useRef(null);
  const refPagItems = useRef([]);
  const refAnswers = useRef([]);
  const refAnswersInners = useRef([]);
  const [currentStage, setCurrentStage] = useState(-1);
  const [isInit, setInit] = useState(false);
  const [isSelected, setSelected] = useState(false);


  useEffect(() => {
    if (data.length > 0 && !isInit) {
      setInit(true);

    }
  }, [data, isInit]);

  useEffect(() => {
    if (!isInit) return;
    const inner = refInner.current;
    inner.style.opacity = 0;
    setTimeout(() => {
      refAnswers.current.forEach((element, index) => {
        if (element === null) return;
        element.classList.remove(styles.active);
      });
      setCurrentStage(stage);
      inner.style.eventPointer = 'none';
    }, 500);
  }, [stage, currentStage, isInit]);

  useEffect(() => {
    if (stage !== currentStage) return;
    const inner = refInner.current;
    inner.style.opacity = 1;
    setTimeout(() => {
      inner.style.eventPointer = '';
      setSelected(false);
    }, 300);
  }, [currentStage, stage]);

  useEffect(() => {
    const pagItems = refPagItems.current;
    pagItems.forEach((element, index) => {
      if (index === currentStage) {
        element.classList.add(styles.active);
      } else if (index < currentStage) {
        element.classList.add(styles.prev);
        element.classList.remove(styles.active);
      } else {
        element.classList.remove(styles.prev);
        element.classList.remove(styles.active);
      }
    });
  }, [currentStage]);


  const clickHandler = (index) => {
    if (isSelected) return;
    refAnswers.current[index].classList.add(styles.active);
    setSelected(true);
    setTimeout(() => {
      updateStage(prev => prev + 1);
    }, 500);
  }
  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.inner} ref={refInner}>
          <div className={styles.question}>
            {data.length > 0 && data[currentStage] !== undefined && data[currentStage].question !== undefined ? data[currentStage].question : null}
          </div>
          <div className={styles.answers}>
            {data.length > 0 && data[currentStage] !== undefined && data[currentStage].answers !== undefined ?
              data[currentStage].answers.map((element, index) => {
                let word = element;
                if(word.indexOf('Wednesday') !== -1){
                  const parts = word.split(new RegExp(`(Wednesday)`, 'gi'));
                  word = parts.map((part, index) => part.toLowerCase() === 'Wednesday'.toLowerCase() ? <span key={index} >{part}</span> : part);
                }
              
                return <div className={styles.answer} key={`answer-${index}`} onClick={() => clickHandler(index)} ref={element => refAnswers.current[index] = element}>
                 <div>{word}</div> 
                </div>
              })
              : null}
          </div>

        </div>
        <div className={styles.pagination}>
          {data.length > 0 ?
            data.map((element, index) => {
              let value = index < 9 ? `0${index + 1}` : index + 1;
              return <div className={styles.pagination__item} key={`pag-${index}`} ref={element => refPagItems.current[index] = element}>
                {value}
              </div>
            })
            :
            null
          }
        </div>
      </div>
    </>
  );

}

export default Test;

