import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Main.module.scss";
import PathContext from "../../context/path";
import Cover from '../../components/Cover/Cover';
import Test from '../../components/Test';
import Result from '../../components/Result/Result';
import StateContext from "../../context/states";
const Main = ()=> {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { isMobile, page } = state;
  const [currentPage, setCurrentPage] = useState(-1);
  const refWrap = useRef(null);
  let currentComponent = null;

  useEffect(() => {
    refWrap.current.style.opacity = 0;
    setTimeout(() => {
      setCurrentPage(page);
      refWrap.current.style.opacity = 1;
    }, 500);
  }, [page]);

  switch (currentPage) {
    case 0:
      currentComponent = <Cover />;
      break;
    case 1:
      currentComponent = <Test />;
      break;
    case 2:
      currentComponent = <Result />;
      break;

    default:
      break;
  }
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        {currentComponent}
      </div>
    </>
  );
   
  
}

export default Main;
