import React, { useContext } from "react";
import styles from "./Header.module.scss";
import PathContext from "../../context/path";
const Header = () => {
  const path = useContext(PathContext);
  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.logo}>
          <img src={`${path}/img/logo.svg`} alt="" />
        </div>
        <div className={styles.bf}>
          <img src={`${path}/img/logo-bf.svg`} alt="" />
        </div>
        
      </div>
    </>
  );

}

export default Header;
